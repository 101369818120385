<template>
    <mf-modal small v-if="isOpen" @close="close">
        <div slot="header">
            <slot name="header">
                Update state
            </slot>
        </div>
        <div class="update-modal">
            <transition name="fade">
                <div v-if="isLoading" class="update-modal__send-message-info">
                    Sending data to PortCDM...
                </div>
            </transition>
            <div class="update-modal__body">
                <div class="update-modal__header">
                    {{ state.name }}
                </div>
                <div class="update-modal__statuses">
                    <mf-radio
                        class="update-modal__status"
                        v-for="type in availableTimeTypes"
                        :checked="timeType === type"
                        @change="changeStatus(type)"
                        :key="`radio-${type}`"
                    >
                        {{ type | capitalize }}
                    </mf-radio>
                </div>
                <div class="update-modal__time-select">
                    <div class="update-modal__time-element">
                        <div class="update-modal__time-label">Date</div>
                        <mf-datepicker v-model="date" full-month-name calendar-button required />
                    </div>
                    <div class="update-modal__time-element">
                        <div class="update-modal__time-label">Time</div>
                        <mf-input :value="time" with-clock :typeable="false" />
                    </div>
                </div>
                <div class="update-modal__time-spins">
                    <div
                        class="update-modal__time-spin"
                        v-for="timeKey in ['year', 'month', 'day', 'hour', 'minute']"
                        :key="timeKey"
                    >
                        <div class="time-spin__item">{{ timeKey }}</div>
                        <mf-button color="blue" size="xx-small" @click="changeTimeByValue(timeKey, 1)">
                            <mf-icon icon-name="icon-plus" height="16" width="16" />
                        </mf-button>
                        <div class="time-spin__item time-spin__item_bold">{{ timeFilter(date, timeKey) }}</div>
                        <mf-button size="xx-small" @click="changeTimeByValue(timeKey, -1)">
                            <mf-icon icon-name="icon-minus" height="16" width="16" />
                        </mf-button>
                    </div>
                </div>
                <div class="update-modal__time-info">
                    <b>{{ timeType | capitalize }}</b> time is <b>{{ humanizeTimeSince }}</b>
                </div>
                <div class="update-modal__line">
                    <mf-input class="update-modal__comment" placeholder="Comment" v-model="comment" />
                </div>
                <div class="update-modal__line" v-if="state.serviceType === 'STATIONARY'">
                    <validation-provider rules="required" immediate v-slot="{ errors }">
                        <mf-select
                            v-model="at"
                            class="update-modal__select"
                            :options="quays"
                            hide-selected
                            label="name"
                            placeholder="Search or select an AT location"
                        >
                            <div slot="caret">
                                <mf-icon class="multiselect__select" icon-name="icon-down" />
                            </div>
                        </mf-select>
                        <div v-if="errors[0]" class="update-modal__require">
                            <mf-icon icon-name="icon-up" width="14" height="14" />
                            <div class="update-modal__require-text">REQUIRE AT LOCATION</div>
                            <mf-icon icon-name="icon-up" width="14" height="14" />
                        </div>
                    </validation-provider>
                </div>
                <template v-else>
                    <div class="update-modal__line">
                        <validation-provider immediate>
                            <mf-select
                                v-model="from"
                                class="update-modal__select"
                                :options="possibleLocations('from')"
                                label="name"
                                placeholder="Search or select an FROM location"
                            >
                                <div slot="caret">
                                    <mf-icon class="multiselect__select" icon-name="icon-down" />
                                </div>
                            </mf-select>
                            <div v-if="notValidateInputValueExist('from')" class="update-modal__require">
                                <mf-icon icon-name="icon-up" width="14" height="14" />
                                <div class="update-modal__require-text">REQUIRE FROM LOCATION</div>
                                <mf-icon icon-name="icon-up" width="14" height="14" />
                            </div>
                        </validation-provider>
                    </div>
                    <div class="update-modal__line">
                        <validation-provider immediate>
                            <mf-select
                                v-model="to"
                                class="update-modal__select"
                                :options="possibleLocations('to')"
                                label="name"
                                placeholder="Search or select an TO location"
                            >
                                <div slot="caret">
                                    <mf-icon class="multiselect__select" icon-name="icon-down" />
                                </div>
                            </mf-select>
                            <div v-if="notValidateInputValueExist('to')" class="update-modal__require">
                                <mf-icon icon-name="icon-up" width="14" height="14" />
                                <div class="update-modal__require-text">REQUIRE TO LOCATION</div>
                                <mf-icon icon-name="icon-up" width="14" height="14" />
                            </div>
                        </validation-provider>
                    </div>
                    <div v-if="from && to && from.name === to.name" class="update-modal__require">
                        <div class="update-modal__require-text">FROM and TO cannot be the same location!</div>
                    </div>
                </template>
            </div>
        </div>
        <div slot="footer">
            <div class="modal__buttons">
                <mf-button class="modal__button" @click="close">Cancel</mf-button>
                <mf-button class="modal__button" color="blue" @click="sendMessage" :disabled="!isSendAvailable">
                    Send
                </mf-button>
            </div>
        </div>
    </mf-modal>
</template>

<script>
    import timeSince from '@/filters/timeSince.filter';
    import messageSubmissionServiceMSS from '@/services/message-submission-service/messageSubmissionServiceMSS';
    import { mapGetters } from 'vuex';

    const TIME_SEQUENCE_VALUES = {
        from: 'DEPARTURE_FROM',
        to: 'ARRIVAL_TO',
    };

    const SERVICE_OBJECT_FOR_DRIFTING = 'DRIFTING';
    const SPECIAL_STATES_IDS_WITH_DIRECTION_OF_MOVEMENT = {
        Arrival_Pilot_Vessel: 'to',
        Departure_Pilot_Vessel: 'from',
        Arrival_EscortTug_Vessel: 'to',
        Departure_EscortTug_Vessel: 'from',
    };

    export default {
        name: 'UpdateStateModal',
        props: {
            isOpen: {
                type: Boolean,
                required: true,
            },
            state: {
                type: Object,
                required: true,
            },
            portCallId: {
                type: String,
            },
            vesselImo: {
                type: String,
            },
            isFirstState: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            ...mapGetters(['quays', 'selectedPortCall', 'userInfo']),
            humanizeTimeSince() {
                const [hours, minutes] = this.time.split(':').map(value => +value);
                const date = new Date(new Date(this.date).setHours(hours, minutes, 0, 0));
                return timeSince(date);
            },
            time() {
                const hours = this.timeFilter(this.date, 'hour');
                const minutes = this.timeFilter(this.date, 'minute');
                return `${hours}:${minutes}`;
            },
            newState() {
                return {
                    ...this.state,
                    stateTime: this.date.toISOString(),
                    timeType: this.timeType,
                    at: this.at,
                    from: this.from,
                    to: this.to,
                    portCallId: this.portCallId,
                    vesselId: this.vesselImo,
                    comment: this.comment,
                };
            },
            isSendAvailable() {
                const isSelectedToLocation =
                    this.to && this.isRequiredLocationType('to') && !this.isRequiredLocationType('from');
                const isSelectedFromLocation =
                    this.from && this.isRequiredLocationType('from') && !this.isRequiredLocationType('to');

                return this.state.serviceType === 'STATIONARY'
                    ? !!this.at
                    : ((!!this.from || !!this.to) &&
                          this.state.serviceObject !== SERVICE_OBJECT_FOR_DRIFTING &&
                          ((this.from && this.to && this.from.name !== this.to.name) ||
                              isSelectedToLocation ||
                              isSelectedFromLocation)) ||
                          (this.from && this.to && this.from.name !== this.to.name);
            },
            availableTimeTypes() {
                return (
                    this.state &&
                    ((this.state.stateType &&
                        this.state.stateType === 'AdministrationState' &&
                        this.timeTypes.filter(type => type === this.timeType)) ||
                        this.timeTypes)
                );
            },
        },
        watch: {
            'state.stateId'() {
                this.at = this.from = this.to = null;
            },
            'state.stateType': {
                handler(val) {
                    if (val === 'AdministrationState') {
                        this.timeType = 'actual';
                    }
                },
                immediate: true,
            },
        },
        data() {
            const today = new Date(new Date().setHours(0, 0, 0));
            return {
                date: today,
                timeType: 'estimated',
                timeTypes: ['recommended', 'target', 'estimated', 'actual'],
                at: null,
                from: null,
                to: null,
                comment: null,
                isLoading: false,
            };
        },
        methods: {
            possibleLocations(type) {
                const isRequiredLocationType = this.isRequiredLocationType(type);
                const directionOfMovementForSpecialState =
                    SPECIAL_STATES_IDS_WITH_DIRECTION_OF_MOVEMENT[this.state.stateId];

                return (
                    this.quays &&
                    this.quays.filter(quay => {
                        const isRightLocationType =
                            this.state.locationType &&
                            quay.locationType &&
                            quay.locationType === this.state.locationType;
                        return (
                            (!this.state.locationType ||
                                !isRequiredLocationType ||
                                isRightLocationType ||
                                (directionOfMovementForSpecialState && directionOfMovementForSpecialState === type)) &&
                            (this.from ? quay.URN !== this.from.URN : true) &&
                            (this.to ? quay.URN !== this.to.URN : true)
                        );
                    })
                );
            },
            close() {
                if (this.isFirstState) {
                    this.$emit('portcallCreatedOrCanceled');
                }
                this.$emit('close');
            },
            changeStatus(value) {
                this.timeType = value;
            },
            async sendMessage() {
                this.isLoading = true;
                try {
                    if (this.newState.createdBy === 'Test') {
                        return;
                    }
                    const newStateData =
                        this.newState.createdBy === 'Carmenta'
                            ? this.newState
                            : { ...this.newState, reportedBy: this.userInfo.email };
                    const response = await messageSubmissionServiceMSS.post(newStateData);

                    await this.$store.dispatch('getPortCall', this.portCallId);
                    await this.$store.dispatch('getPortCallStatements', response.data);
                    await this.$store.dispatch('getPortCallEvents', this.portCallId);

                    if (this.isFirstState) {
                        this.$store.commit('addPortCall', this.selectedPortCall);
                    }
                    this.$toasted.success('Data received');
                } catch {
                    this.$toasted.error('SENDING DATA FAILED!');
                } finally {
                    this.close();
                    this.isLoading = false;
                }
            },
            changeTimeByValue(key, value) {
                switch (key) {
                    case 'year':
                        this.date = new Date(this.date.setFullYear(this.date.getFullYear() + value));
                        break;
                    case 'month':
                        this.date = new Date(this.date.setMonth(this.date.getMonth() + value));
                        break;
                    case 'day':
                        this.date = new Date(this.date.setDate(this.date.getDate() + value));
                        break;
                    case 'hour':
                        this.date = new Date(this.date.setHours(this.date.getHours() + value));
                        break;
                    case 'minute':
                        this.date = new Date(this.date.setMinutes(this.date.getMinutes() + value));
                        break;
                }
            },
            timeFilter(value, filter = 'minute') {
                const formatNumberToTwoDigitNumber = value => {
                    return +value > 9 ? value : `0${value}`;
                };
                switch (filter) {
                    case 'year':
                        return value.getFullYear();
                    case 'month':
                        return formatNumberToTwoDigitNumber(value.getMonth() + 1);
                    case 'day':
                        return formatNumberToTwoDigitNumber(value.getDate());
                    case 'hour':
                        return formatNumberToTwoDigitNumber(value.getHours());
                    case 'minute':
                        return formatNumberToTwoDigitNumber(value.getMinutes());
                }
            },
            notValidateInputValueExist(type) {
                const requireForm = this.isRequiredLocationType(type);

                return requireForm && this.newState[type] === null;
            },
            isRequiredLocationType(type) {
                const { locationStateDetails, timeSequence } = this.newState;
                const isRightLocationTimeSequence =
                    locationStateDetails && locationStateDetails.locationTimeSequence === TIME_SEQUENCE_VALUES[type];
                const isRightTimeSequence = timeSequence === TIME_SEQUENCE_VALUES[type];

                return (
                    isRightLocationTimeSequence ||
                    isRightTimeSequence ||
                    this.newState.serviceObject === SERVICE_OBJECT_FOR_DRIFTING
                );
            },
        },
    };
</script>

<style lang="scss" scoped>
    .update-modal {
        display: flex;
        flex-direction: column;
    }

    .update-modal__send-message-info {
        position: absolute;
        top: 20px;
        right: 5%;
        padding: $space-12;
        background-color: $white;
        border-radius: $border-radius-4;
        border: 1px solid $marine-500;
        @include media($md) {
            top: 5%;
        }
    }

    .update-modal__body {
        padding: $space-8;
    }

    .update-modal__header {
        font-weight: bold;
        font-size: $font-16;
        line-height: $font-24;
        text-align: center;
        color: $white;
        margin-bottom: $space-40;
    }

    .update-modal__statuses {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: (-$space-10) (-$space-10) $space-32;
    }

    .update-modal__status {
        padding: $space-10;
    }

    .update-modal__time-select {
        display: flex;
        flex-wrap: wrap;
        margin: (-$space-10);
    }

    .update-modal__time-element {
        padding: $space-10;
    }

    .update-modal__time-label {
        font-size: $font-14;
        line-height: $font-20;
        color: $white;
        margin-bottom: $space-8;
    }

    .update-modal__time-info {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: $white;
        margin: $space-24 0;
    }

    .update-modal__time-spins {
        display: flex;
        justify-content: space-between;
        margin: $space-24 0;
    }

    .update-modal__time-spin {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 $space-8;
        font-size: $font-14;
        line-height: $font-18;
        color: $white;
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }

    .time-spin__item {
        width: 100%;
        text-align: center;
        margin: $space-8 0;
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    .time-spin__item_bold {
        font-size: $font-16;
        font-weight: bold;
    }

    .update-modal__require {
        margin: $space-20 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: $font-14;
        line-height: $font-24;
        text-align: center;
        color: $red-500;
        svg {
            margin-bottom: 0;
        }
    }

    .update-modal__require-text {
        margin: 0 $space-8;
    }

    .update-modal__line {
        margin-top: $space-32;
    }

    .modal__buttons {
        display: flex;
        justify-content: flex-end;
    }

    .modal__button:not(:first-child) {
        margin-left: $space-8;
    }

    @import '@/assets/sass/transitions/fade';
</style>
