const MILLISECONDS_IN_SECOND = 1000;
const MILLISECONDS_IN_MINUTE = MILLISECONDS_IN_SECOND * 60;
const MILLISECONDS_IN_HOUR = MILLISECONDS_IN_MINUTE * 60;
const MILLISECONDS_IN_DAY = MILLISECONDS_IN_HOUR * 24;
const MILLISECONDS_IN_MONTH = MILLISECONDS_IN_DAY * 30;
const MILLISECONDS_IN_YEAR = MILLISECONDS_IN_MONTH * 12;

export default function timeSince(dateString) {
    const date = new Date(dateString);
    let diff = Math.floor(date - new Date());
    const isPreviousDate = diff < 0;
    isPreviousDate && (diff = Math.floor(new Date() - date));
    let interval = Math.abs(Math.floor(diff / MILLISECONDS_IN_YEAR));
    if (Math.abs(interval) >= 1) {
        const yearLabel = interval === 1 ? 'a year' : `${interval} years`;
        return isPreviousDate ? `${yearLabel} ago` : `in ${yearLabel}`;
    }
    interval = Math.abs(Math.floor(diff / MILLISECONDS_IN_MONTH));
    if (Math.abs(interval) >= 1) {
        const monthLabel = interval === 1 ? 'a month' : `${interval} months`;
        return isPreviousDate ? `${monthLabel} ago` : `in ${monthLabel}`;
    }
    interval = Math.abs(Math.floor(diff / MILLISECONDS_IN_DAY));
    if (Math.abs(interval) >= 1) {
        const dayLabel = interval === 1 ? 'a day' : `${interval} days`;
        return isPreviousDate ? `${dayLabel} ago` : `in ${dayLabel}`;
    }
    interval = Math.abs(Math.floor(diff / MILLISECONDS_IN_HOUR));
    if (Math.abs(interval) >= 1) {
        const hourLabel = interval === 1 ? 'an hour' : `${interval} hours`;
        return isPreviousDate ? `${hourLabel} ago` : `in ${hourLabel}`;
    }
    interval = Math.abs(Math.floor(diff / MILLISECONDS_IN_MINUTE));
    if (Math.abs(interval) >= 1) {
        const minuteLabel = interval === 1 ? 'a minute' : `${interval} minutes`;
        return isPreviousDate ? `${minuteLabel} ago` : `in ${minuteLabel}`;
    }
    const secondLabel = 'a few seconds';
    return isPreviousDate ? `${secondLabel} ago` : `in ${secondLabel}`;
}
