<template>
    <div class="vessel-info__photo" :class="{ 'vessel-info__photo_large': !small }">
        <img
            class="vessel-info__image"
            :class="{ 'vessel-info__image_small': small }"
            :src="portcall.vessel.photoUrl || defaultImage"
            alt="vessel-image"
        />
        <div
            class="vessel-info__favorite"
            :class="{ 'vessel-info__favorite_small': small, 'vessel-info__favorite_active': isFavorite }"
            @click.stop="toggleFavorite"
        >
            <mf-icon icon-name="icon-favorites" width="12" height="12" />
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import defaultImage from '@/assets/images/default-vessel.jpg';

    export default {
        name: 'VesselImage',
        props: {
            portcall: {
                type: Object,
                required: true,
            },
            small: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            ...mapGetters(['favouritePortcalls']),
            isFavorite() {
                return this.favouritePortcalls.portcalls.includes(this.portcall.portCallId);
            },
        },
        data() {
            return {
                defaultImage,
            };
        },
        methods: {
            toggleFavorite() {
                this.$store.commit('toggleFavouritePortcall', this.portcall.portCallId);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .vessel-info__photo {
        position: relative;
    }

    .vessel-info__photo_large {
        margin: 0 auto;
        @include media($sm) {
            margin: 0;
            width: auto;
        }
    }

    .vessel-info__image {
        height: 80px;
        width: 110px;
        border-radius: 8px;
    }

    .vessel-info__image_small {
        height: 60px;
        width: 60px;
    }

    .vessel-info__favorite {
        cursor: pointer;
        position: absolute;
        bottom: -3px;
        right: -3px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 24px;
        width: 24px;
        background: $marine-400;
        border-radius: 60px;
        color: $white;
        svg {
            margin-bottom: 0;
        }
    }

    .vessel-info__favorite_small {
        bottom: -2px;
        right: -2px;
        height: 18px;
        width: 18px;
    }

    .vessel-info__favorite_active {
        background: $green-700;
    }
</style>
