export const statesIds = [
    'Arrival_Pilot_Berth',
    'Departure_Pilot_Berth',
    'Arrival_Pilot_PilotBA',
    'Departure_Pilot_PilotBA',
    'Arrival_Pilot_Vessel',
    'Departure_Pilot_Vessel',
];

export const statesDefinitions = [
    'Arrival Pilot Berth',
    'Departure Pilot Berth',
    'Arrival Pilot PilotBoardingArea',
    'Departure Pilot PilotBoardingArea',
    'Arrival Pilot Vessel',
    'Departure Pilot Vessel',
];
