import { Api, convertObjectKeys } from '@/services/apiService';
import buildPortCallMessage from '@/helpers/buildPortCallMessage.helper';
import withdrawnPortCallMessage from '@/helpers/withdrawnPortCallMessage';
import { statesIds, statesDefinitions } from '@/config/locationStatesInfoWithPilotObject';
import { snakeCase } from 'lodash';

class messageSubmissionServiceMSS {
    static post(data, withdrawn = false) {
        if (
            (data.createdBy && data.createdBy !== 'Carmenta') ||
            (data.createdByUserOf && data.createdByUserOf !== 'Carmenta') ||
            statesIds.includes(data.stateId) ||
            (withdrawn && statesDefinitions.includes(data.stateDefinition))
        ) {
            if (!withdrawn) {
                let message = buildPortCallMessage(data);
                message = convertObjectKeys(message, snakeCase);

                return Api.post('/portcall/state', message, { baseURL: '/new-api' });
            } else {
                let message = {
                    portcallId: data.portCallId,
                    messageId: data.messageId,
                };
                message = convertObjectKeys(message, snakeCase);

                return Api.put('/portcall/withdraw-state', message, { baseURL: '/new-api' });
            }
        } else {
            const message = withdrawn ? withdrawnPortCallMessage(data) : buildPortCallMessage(data, true);
            return Api.post('/mb/mss/', message, {
                headers: { 'content-type': 'application/xml' },
            });
        }
    }
}

export default messageSubmissionServiceMSS;
